export const templateJson = {
  title: '2024 東陽 OEM 事業群台灣區供應鏈 ESG 大調查',
  logo: 'https://i.postimg.cc/fyfWfddj/LOGO-L.png',
  logoPosition: 'left',
  // 1
  completedHtml: '',
  // 2
  completeText: '',
  pageNextText: '下一頁',
  pagePrevText: '上一頁',
  // 3
  completedBeforeHtml: '',
  locale: 'zh-tw',
  pages: [
    {
      name: 'page0',
      elements: [
        {
          type: 'panel',
          elements: [
            {
              type: 'html',
              name: 'intro',
              // 4
              html: '',
            },
          ],
          name: 'panel1',
        },
      ],
    },
  ],
  startSurveyText: '開始作答',
  firstPageIsStarted: true,
  showPrevButton: false,
}
