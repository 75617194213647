import React, { useRef } from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import '../index.css'
// import { useNavigate } from 'react-router-dom'
import { useSubmitAnswers } from '../hooks/useSubmitAnswers'
// import { Tooltip } from 'react-tooltip'

function SurveyComponent({
  convertedSurvey,
  ac,
  tk,
  pid,
  imagesReadingTime,
  setIsLoading,
  setImagesReadingTime,
}) {
  // 問卷編號
  // const pid = pid
  // 專案編號
  const pi = pid

  const choicesOrderRef = useRef({})
  const choiceIndexMapRef = useRef({})
  const survey = new Model(convertedSurvey)
  // 使⽤者上傳填答結果
  // 檢查是否存在帶有 'imgWrap50' className 的元素
  function hideSdBtnIfImgWrapExists() {
    if (document.querySelector('.imgWrap50')) {
      // 如果存在，找到所有帶有 'sd-btn' className 的元素
      const sdBtnElements = document.querySelectorAll('.sd-btn')
      const thirtySecondDelete = document.querySelectorAll(
        '.thirtySecondDelete'
      )

      // 遍歷每一個 'sd-btn' 元素並設置其 display 為 'none'
      sdBtnElements.forEach((element) => {
        element.style.display = 'none'
      })

      // 30秒後恢復 'sd-btn' 元素的顯示
      setTimeout(() => {
        sdBtnElements.forEach((element) => {
          element.style.display = ''
        })
        thirtySecondDelete.forEach((element) => {
          element.style.display = 'none'
        })
        // TODO:
      }, imagesReadingTime * 1000) // 30秒
    }
  }

  // 多選之後排序
  const questionMappings = {
    q6: 'q7',
    q14: 'q15',
    q23: 'q24',
    q25: 'q26',
    q35: 'q36',
  }

  // 4個答案都要4
  function areAllAnswersFour(survey) {
    return (
      survey.getValue('q10') === 4 &&
      survey.getValue('q11') === 4 &&
      survey.getValue('q12') === 4 &&
      survey.getValue('q13') === 4
    )
  }
  // 4個答案都要0
  function areAllAnswersZero(survey) {
    return (
      survey.getValue('q10') === 0 &&
      survey.getValue('q11') === 0 &&
      survey.getValue('q12') === 0 &&
      survey.getValue('q13') === 0
    )
  }

  function helpFunction(survey, options) {
    // 參考 P231113152849 或 P231101165555
    // 多選之後排序小幫手

    if (questionMappings.hasOwnProperty(options.name)) {
      // 篩選變下一題的選項

      const targetQuestionName = questionMappings[options.name]
      const targetQuestion = survey.getQuestionByName(targetQuestionName)
      const sourceQuestion = survey.getQuestionByName(options.name)
      let choices = []

      if (options.value) {
        options.value.forEach(function (choiceValue, index) {
          if (typeof choiceValue === 'number') {
            const foundChoice = sourceQuestion.choices.find(
              (choice) => choice.value === choiceValue
            )

            const choiceText = foundChoice ? foundChoice.text : choiceValue

            if (
              choiceText ===
              '政府加強勞動權益保護或稽核||如：政府持續調高基本工資、強化非典型勞動權益保障、要求建立性別友善與育兒友善的職場環境…等'
            ) {
              choices.push({ value: index, text: '政府加強勞動權益保護或稽核' })
            } else {
              choices.push({ value: index, text: choiceText })
            }
          } else if (choiceValue === 'other') {
            choices.push({
              value: index,
              text: '其他(上題填寫內容)',
            })
          } else if (choiceValue === 'none') {
            choices.push({
              value: index,
              text: options.question.noneText,
            })
          }
        })
      }

      // targetQuestion.showOtherItem = sourceQuestion.isOtherSelected
      // targetQuestion.otherText = sourceQuestion.otherText

      // targetQuestion.showNoneItem = sourceQuestion.isNoneSelected
      // targetQuestion.noneText = sourceQuestion.noneText

      targetQuestion.choices = choices
      if (
        targetQuestionName === 'q24' ||
        targetQuestionName === 'q26' ||
        targetQuestionName === 'q36'
      ) {
        targetQuestion.minSelectedChoices = choices.length
      }

      // targetQuestion.defaultValue = []

      // 最後回傳的 order 要改
      const order = targetQuestion.visibleChoices
      if (order) {
        // 將選項順序設為每個選項的索引值
        choiceIndexMapRef.current[targetQuestionName] = order.reduce(
          (map, choice, index) => {
            map[index] = { index: index, title: choice.title }
            return map
          },
          {}
        )
        // console.log('choiceIndexMapRef', choiceIndexMapRef)
      }
    }

    // 亂填就刪第二部分
    options.allowChanging = false
    if (areAllAnswersFour(survey)) {
      // 刪除第二部分
      survey.pages[2].visible = false
      survey.pages[3].visible = false
      survey.pages[4].visible = false
      survey.pages[5].visible = false

      survey.completedHtml = `<p>建議您將本問卷連結，轉送給貴公司負責企業永續的最高主管填答。`
    } else if (areAllAnswersZero(survey)) {
      survey.pages[2].visible = false
      survey.pages[3].visible = false
      survey.pages[4].visible = false

      survey.completedHtml = `<p>由衷感謝您的寶貴時間與支持。</p><p>歡迎您將這份問卷連結，轉傳給更多企業主，或負責企業永續的企業朋友。</p><p>因為有大家的參與，對於共同探尋適合台灣企業的永續轉型之道，有莫大助益。</p>`
    } else if (!areAllAnswersFour(survey) && !areAllAnswersZero(survey)) {
      survey.pages.forEach((page) => (page.visible = true))
      survey.completedHtml = `<p>由衷感謝您的寶貴時間與支持。</p><p>歡迎您將這份問卷連結，轉傳給更多企業主，或負責企業永續的企業朋友。</p><p>因為有大家的參與，對於共同探尋適合台灣企業的永續轉型之道，有莫大助益。</p>`
    }
  }

  const canShowPrevButtonPages = null

  // 專有名詞解釋
  const titleDivider = '||'
  function updateStringComponents(_, options) {
    options.htmlElement.querySelectorAll('.sv-string-viewer').forEach((el) => {
      const text = el.innerText
      if (text.indexOf(titleDivider) > -1) {
        const strings = text.split(titleDivider)
        // el.title = strings[1]
        el.innerText = strings[0]
        // el.classList.add('mystyle')
        el.innerHTML =
          el.innerHTML +
          ` <button class="tooltip mybutton">?<div class="tooltiptext">${strings[1]}</div></button>`
        // button 會讓字掉下來
        // `<button class="tooltip mybutton"><img style='margin:4px 0' src="images/circle-question-solid.svg" alt="circle-question"><span class="tooltiptext">${strings[1]}</span></button>`
      }
    })
  }

  useSubmitAnswers({
    survey,
    pid,
    pi,
    ac,
    tk,
    hideSdBtnIfImgWrapExists,
    setIsLoading,
    helpFunction,
    canShowPrevButtonPages,
    setImagesReadingTime,
    updateStringComponents,
    choicesOrderRef,
    choiceIndexMapRef,
  })

  return <Survey model={survey} />
}

export default SurveyComponent
