import React, { useState, useEffect } from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import '../index.css'
import { templateJson } from './json/templateJson'

import axios from 'axios'

const Login = ({ setCurrentUser, setAc, setTk, setIsLoading }) => {
  const host = 'https://nezcdev.dataa.com.tw'

  let myLoginJson = {
    ...templateJson,
    completedHtml: `<div class="sd-element--with-frame"><h3>問卷準備中...</h3><h3>若逾時過久請連絡小編，謝謝</h3></div>`,
    completeText: '進入問卷',
    completedBeforeHtml: '<h3>您已填過此問卷</h3>',
    pages: [
      {
        name: 'page0',
        elements: [
          {
            type: 'panel',
            elements: [
              {
                type: 'html',
                name: 'intro',
                html: '<p>本問卷旨在調查台灣中小企業面對全球永續議題的回應與作為，期待透過此問卷瞭解中小企業目前在環境保護、社會責任和公司治理各面向的現況。本問卷的數據分析報告，將分享給所有參與調查的中小企業，並提供相關資源的資訊，希望藉此協助中小企業在永續轉型的路上，同步掌握多元產業的永續發展動態。</p><p>誠摯邀請東陽 OEM 事業群台灣區供應鏈的<u>企業主</u>，或<u>公司負責企業永續的最高主管</u>，參與本調查，整份問卷的填答約需 15~20 分鐘。您的填答對於反應貴企業的真實現況十分重要，您所填答的資料僅供整體產業分析之用，敬請放心填答。</p>',
              },
            ],
          },
        ],
      },
    ],
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const survey = new Model(myLoginJson)
  // 問卷編號
  // const pid = randomPid
  // 員⼯驗證
  const UserWebAuthorize = '/clientWeb/v1/UserWebAuthorize'

  const [userId, setuserId] = useState(null)

  // 下一頁後會拉到最頂端
  survey.onCurrentPageChanged.add(function (survey, options) {
    window.scrollTo(0, 0)
  })
  useEffect(() => {
    survey.onComplete.add((sender, options) => {
      setuserId(true)
    })
  }, [survey])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.post(
          host + UserWebAuthorize,
          { type: 1 },
          {
            headers: {
              'Content-Type': 'text/plain; charset=UTF-8',
              Accept: '*/*',
              ai: 'EZC-Web',
            },
          }
        )
        setIsLoading(false)
        // console.log('response', response)
        // setIsLoading(false)
        if (response.data.code === '02040') {
          setCurrentUser(userId)
          setAc(response.headers.ac)
          setTk(response.headers.tk)
        } else {
          alert('伺服器忙碌中，請稍後再試，謝謝！')
        }
      } catch (e) {
        setIsLoading(false)
        console.log(e)
        alert('伺服器忙碌中，請稍後再試，謝謝！')
      }
    }
    if (userId) {
      fetchData()
    }
  }, [userId])

  return <Survey model={survey} />
}

export default Login
