import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Login from './Login'
import SurveyComponent from './SurveyComponent'
import Agree from './Agree'
import BarLoader from 'react-spinners/BarLoader'
import { AuthContext } from './AuthContext'
import { Helmet } from 'react-helmet'

function App() {
  const [currentUser, setCurrentUser] = useState(null)
  const [ac, setAc] = useState(null)
  const [tk, setTk] = useState(null)
  const [convertedSurvey, setconvertedSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imagesReadingTime, setImagesReadingTime] = useState(0)

  // const { pathname } = useLocation()

  // Find the corresponding pid
  const pid = 'P231221111651'
  // console.log('pathname.split', pathname.split('/')[1])

  // BarLoader
  const actualInnerWidth = document.body.clientWidth - 20

  return (
    <>
      <BarLoader
        cssOverride={{ overflow: 'hidden', position: 'fixed', zIndex: '1021' }}
        color="#19b394"
        width={actualInnerWidth}
        loading={isLoading}
      />
      <AuthContext.Provider value={{ setIsLoading, isLoading }}>
        <Routes>
          {!currentUser && (
            <Route
              path="/"
              element={
                <Login
                  setIsLoading={setIsLoading}
                  setCurrentUser={setCurrentUser}
                  setAc={setAc}
                  setTk={setTk}
                />
              }
            />
          )}
          {currentUser && !convertedSurvey && (
            <Route
              path="/"
              element={
                <Agree
                  setIsLoading={setIsLoading}
                  pid={pid}
                  ac={ac}
                  tk={tk}
                  setconvertedSurvey={setconvertedSurvey}
                  imagesReadingTime={imagesReadingTime}
                />
              }
            />
          )}

          {convertedSurvey && (
            <Route
              path="/"
              element={
                <SurveyComponent
                  setIsLoading={setIsLoading}
                  pid={pid}
                  ac={ac}
                  tk={tk}
                  convertedSurvey={convertedSurvey}
                  imagesReadingTime={imagesReadingTime}
                  setImagesReadingTime={setImagesReadingTime}
                />
              }
            />
          )}
        </Routes>
      </AuthContext.Provider>
    </>
  )
}

export default App
