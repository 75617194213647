import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import axios from 'axios'
import App_231206105643 from './P231206105643/App'
import App_240123182109 from './P240123182109/Layout'
import App_231221111653 from './P231221111653/Layout'
import App_231221111651 from './P231221111651/Layout'
import App_240307180500 from './P240307180500/Layout'
import App_240307180501 from './P240307180501/Layout'

// import Home from './home/App'
// import Test from './test/App'

import IsSubmitted from './IsSubmitted'
import Closed from './Closed'
import Error from './Error'
import NotFound from './NotFound'
const routeMapping = [
  'ykll4',
  'z2b3n',
  'dc2ia',
  'l2oxy',
  'woey5',
  'rxtie',
  'wen3f',
  'zz2ha',
  'tzuwc',
  'enwyk',
  'dssfh',
  'm3xph',
  'ubav0',
  'a8xh1',
  'lkgch',
  'qet85',
]

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/p231206105643/*" element={<App_231206105643 />} /> */}
          {/* <Route path="/:pid/*" element={<App_231206105643 />} /> */}
          {routeMapping.map((path) => (
            <Route
              key={path}
              path={`/${path}/*`}
              element={<App_231206105643 />}
            />
          ))}
          {/* 天下 */}
          <Route path="/nobg5/*" element={<App_240123182109 />} />
          {/* 塑膠 B */}
          <Route path="/w9bpi/*" element={<App_231221111651 />} />
          {/* 塑膠 A */}
          <Route path="/nqpgl/*" element={<App_231221111653 />} />
          {/* 東陽 */}
          <Route path="/vcs3m/*" element={<App_240307180500 />} />
          {/* 上銀 */}
          <Route path="/uuns6/*" element={<App_240307180501 />} />

          {/* ============ 404 回首頁 ============ */}

          <Route path="/:pid/closed" element={<Closed />} />
          <Route path="/:pid/done" element={<IsSubmitted />} />
          <Route path="/:pid/submitFail" element={<Error />} />
          <Route path="/:pid/notFound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
