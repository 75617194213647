import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Login from './Login'
import SurveyComponent from './SurveyComponent'
import Agree from './Agree'
import BarLoader from 'react-spinners/BarLoader'
import { AuthContext } from './AuthContext'

function App() {
  const [currentUser, setCurrentUser] = useState(null)
  const [ac, setAc] = useState(null)
  const [tk, setTk] = useState(null)
  const [convertedSurvey, setconvertedSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imagesReadingTime, setImagesReadingTime] = useState(0)

  const { pathname } = useLocation()
  const routeMapping = {
    z2b3n: '231206105643',
    dc2ia: '231221111636',
    l2oxy: '231221111640',
    woey5: '231221111642',
    rxtie: '231221111644',
    wen3f: '231221111647',
    zz2ha: '231221111649',
    ykll4: '231221111655',
    tzuwc: '240307180453',
    enwyk: '240307180455',
    dssfh: '240307180456',
    m3xph: '240307180457',
    ubav0: '240307180459',
    a8xh1: '240307180503',
    lkgch: '240307180504',
    qet85: '240307180505',
  }
  // Extract the relevant part of the pathname
  const pathKey = pathname.split('/')[1]

  // Find the corresponding pid
  const pid = 'P' + routeMapping[pathKey]

  // const pid = useParams().pid.toUpperCase()
  // console.log('app', pid)

  // BarLoader
  const actualInnerWidth = document.body.clientWidth - 20

  return (
    <>
      <BarLoader
        cssOverride={{ overflow: 'hidden', position: 'fixed', zIndex: '1021' }}
        color="#19b394"
        width={actualInnerWidth}
        loading={isLoading}
      />
      <AuthContext.Provider value={{ setIsLoading, isLoading }}>
        <Routes>
          {!currentUser && (
            <Route
              path="/"
              element={
                <Login
                  setIsLoading={setIsLoading}
                  setCurrentUser={setCurrentUser}
                  setAc={setAc}
                  setTk={setTk}
                />
              }
            />
          )}
          {currentUser && !convertedSurvey && (
            <Route
              path="/"
              element={
                <Agree
                  setIsLoading={setIsLoading}
                  pid={pid}
                  ac={ac}
                  tk={tk}
                  setconvertedSurvey={setconvertedSurvey}
                  imagesReadingTime={imagesReadingTime}
                />
              }
            />
          )}

          {convertedSurvey && (
            <Route
              path="/"
              element={
                <SurveyComponent
                  setIsLoading={setIsLoading}
                  pid={pid}
                  ac={ac}
                  tk={tk}
                  convertedSurvey={convertedSurvey}
                  imagesReadingTime={imagesReadingTime}
                  setImagesReadingTime={setImagesReadingTime}
                />
              }
            />
          )}
        </Routes>
      </AuthContext.Provider>
    </>
  )
}

export default App
