import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
// import 'survey-core/defaultV2.min.css'
import { useMediaQuery } from 'react-responsive'
// import noUiSlider from 'nouislider'
import { nouislider } from 'surveyjs-widgets'
import * as SurveyCore from 'survey-core'
// import 'nouislider/dist/nouislider.css'
nouislider(SurveyCore)

export function useConvertSurvey({
  survey,
  pid,
  ac,
  tk,
  myAgreeJson,
  setMyAgreeJson,
  setconvertedSurvey,
  imagesReadingTime,
  templateJson,
  setIsLoading,
  completedHtml,
  visibleIfConditionsInit,
  notRequired,
  mappedRateChoice,
  pageBreakQuestions,
  triggersConditionsInit,
  // testJSON,
}) {
  const host = 'https://nezcdev.dataa.com.tw'

  // 使⽤者加入問卷
  const JoinChat = '/clientWeb/v1/JoinChat'
  // 使⽤者取得問卷
  const GetQuestions = '/clientWeb/v1/GetQuestions'
  // 取得問卷資訊
  const getProjectInfo = '/clientWeb/v1/GetProjectInfo'

  // discord
  async function sendDiscordNotification(message) {
    try {
      await axios.post('/api/send-notification', {
        content: message,
      })
    } catch (error) {
      console.error('Failed', error)
    }
  }
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

  const [isAgree, setIsAgree] = useState(false)
  const [receivedSurvey, setReceivedSurvey] = useState(null)
  const [newSurvey, setNewSurvey] = useState(null)

  const pageOrderRef = useRef(1)

  const newSurveySettings = {
    ...templateJson,
    completeText: '完成作答',
    startSurveyText: '下一頁',
    firstPageIsStarted: false,
    showProgressBar: 'bottom',
    showPrevButton: true,
    widthMode: isMobile ? 'responsive' : 'static',
    width: isMobile ? '' : '800px',
    completedHtml: completedHtml,
    triggers: [],
  }
  let tempSurvey = {
    ...newSurveySettings,
    pages: [{ name: 'page0', elements: [] }],
  }
  // showOtherItem
  function checkShowOtherItem(data) {
    for (let i = 0; i < data.op.length; i++) {
      if (data.op[i].o_type === 6) {
        return true
      }
    }

    return false
  }
  // otherText
  function checkOtherText(data) {
    // 遍歷op對象
    for (let i = 0; i < data.op.length; i++) {
      // 如果找到"o_type": 6
      if (data.op[i].o_type === 6) {
        return data.op[i].na
      }
    }

    return ''
  }
  // placeholder
  function checkPlaceholder(data) {
    if (data.cf.q_type === 4) {
      return data.op[0].na
    }

    return ''
  }

  // random 小工具
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  // choices
  function convertChoices(data) {
    // 過濾掉 none 選項
    let filteredNoneChoices = data.op
    if (data.cf.parameters.group && data.op[0]?.o_type !== 1) {
      if (data.cf.parameters.group.length > 0) {
        filteredNoneChoices = filteredNoneChoices.filter(
          (v, i) => i !== data.cf.parameters.group[0]
        )
      }
    }

    let filterOtherChoices = filteredNoneChoices.filter((v) => {
      return v.o_type === 0 || v.o_type === 1
    })
    let mapedChoices = filterOtherChoices.map((v, i) => {
      return {
        value: i,
        text: v.na,
        imageLink: v.o_type === 1 ? v.lnk : '',
      }
    })

    if (data.cf.parameters.random.is_rand === 1) {
      const randomCount = data.cf.parameters.random.rand[0].length
      let choices = mapedChoices
      // 只取前 X 個選項進行隨機排序
      // let randomChoices = choices
      //   .slice(0, randomCount)
      //   .sort(() => Math.random() - 0.5)

      let randomChoices = shuffle(choices.slice(0, randomCount))
      // 將排序後的選項與其餘選項合併
      choices = randomChoices.concat(choices.slice(randomCount))
      // 更新題目的選項
      mapedChoices = choices
      // console.log('mapedChoices', mapedChoices)
      if (data.cf.parameters.random.rand.length > 1) {
        // const firstRandom = data.cf.parameters.random.rand[0]
        // const secondRandom = data.cf.parameters.random.rand[1]
        // const firstRandomCount = firstRandom.length
        // const secondRandomCount = secondRandom.length

        // let firstRandomChoices = choices
        //   .slice(0, firstRandomCount)
        //   .sort(() => Math.random() - 0.5)
        // let secondRandomChoices = choices
        //   .slice(secondRandom[0], secondRandom[0] + secondRandomCount)
        //   .sort(() => Math.random() - 0.5)

        console.log('神秘區域SOS')
      }
    }

    return mapedChoices
  }

  function convertDropdownChoices(data) {
    let mapedChoices = data.op.map((v, i) => {
      return {
        value: i,
        text: v.na,
      }
    })

    return mapedChoices
  }

  let rateChoice = -1
  // 尺規題選項
  function convertRateValues() {
    if (mappedRateChoice !== null) {
      rateChoice++
      return mappedRateChoice[rateChoice]
    }
  }

  // 非必填
  function checkNotRequired(questionIndex) {
    // TODO: 上是對的
    let returnValue = true
    // let returnValue = false
    notRequired.forEach((i) => {
      if (questionIndex === i) {
        returnValue = false
        // returnValue = true
      }
    })

    return returnValue
  }

  // showNoneItem
  function checkShowNoneItem(data) {
    if (data.cf.parameters.group) {
      if (data.cf.parameters.group.length > 0) {
        return true
      }
    }
    return false
  }
  // showNoneItem
  function checkNoneText(data) {
    if (data.cf.parameters.group) {
      if (data.cf.parameters.group > 0) {
        return data.op[data.cf.parameters.group[0]].na
      }
    }

    return ''
  }

  // multipleImages
  function multipleImages(data) {
    let img = ''
    for (let i = 0; i < data.ma.images.length; i++) {
      img += `<img src=${data.ma.images[i]} alt="image" />`
    }

    return `<div class="imgWrap50"><h2 style="text-align: center">請仔細觀看再進入下一頁</h2>${img}<h2 class="thirtySecondDelete" style="text-align: center">${imagesReadingTime} 秒後才能進入下一頁</h2></div>`
  }

  // 矩陣欄選項
  function convertMatrixColumns(data) {
    let mapedChoices = data.cf.parameters.row_op.map((v, i) => {
      return {
        value: i,
        text: v,
      }
    })

    return mapedChoices
  }
  // 矩陣列選項
  function convertMatrixRows(data) {
    let mapedChoices = data.op.map((v, i) => {
      return {
        value: i,
        text: v.na,
      }
    })

    return mapedChoices
  }

  // 已達本問卷作答人數限制
  useEffect(() => {
    setIsLoading(true)
    axios
      .post(
        host + getProjectInfo,
        { pid: pid },
        {
          headers: {
            'Content-Type': 'text/plain; charset=UTF-8',
            Accept: '*/*',
            ac: ac,
            tk: tk,
          },
        }
      )
      .then((res) => {
        if (res.data.is_full) {
          setMyAgreeJson({
            ...myAgreeJson,
            completedHtml: `<h3>感謝您的參與</h3> <h4>如有填卷相關問題請洽：</h4><a href='mailto:chuheng@nccu.edu.tw'>chuheng@nccu.edu.tw</a><h4 style='margin-top:50px'>更多企業倫理與永續經營訊息</h4> <a href='https://syschool.nccu.edu.tw/'>https://syschool.nccu.edu.tw/</a>`,
            completeText: '完成',
            pages: [
              {
                name: 'page0',
                elements: [
                  {
                    type: 'panel',
                    elements: [
                      {
                        type: 'html',
                        name: 'intro',
                        html: "<h2 class='intro__heading title'>已達本問卷作答人數限制</h2>",
                      },
                    ],
                  },
                ],
              },
            ],
          })
        }
      })
      .catch((e) => {
        console.log('e', e)
        if (e.response.data.code === '04008') {
          navigate(`/pid=${pid}/notFound`)
        }
        sendDiscordNotification(
          `信義書院 GetProjectInfo Error \n Error: ${e} \n pid: ${pid} \n ac: ${ac} \n tk: ${tk} \n Response: ${e.response?.data?.msg} \n Response Code: ${e.response?.data?.code}`
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  //   我同意
  useEffect(() => {
    survey.onComplete.add((sender, options) => {
      let surveyData = sender.data
      if (surveyData.agree === 1) {
        setIsAgree(true)
      }
      // console.log('surveyData.agree', surveyData.agree)
    })
  }, [survey])

  //  加入問卷&拉問卷
  useEffect(() => {
    if (isAgree) {
      setIsLoading(true)
      const JoinChatPost = async () => {
        try {
          const response = await axios.post(
            host + JoinChat,
            { pid: pid },
            {
              headers: {
                'Content-Type': 'text/plain; charset=UTF-8',
                Accept: '*/*',
                ac: ac,
                tk: tk,
              },
            }
          )
          //  填過的狀態
          if (response.data.code === '03044') {
            navigate(`/pid=${pid}/done`)
          } else if (response.data.code === '03046') {
            navigate(`/pid=${pid}/closed`)
          } else {
            GetQuestionsPost()
          }
        } catch (e) {
          console.log(e)
          sendDiscordNotification(
            `信義書院 JoinChatPost Error \n Error: ${e}  \n pid: ${pid} \n ac: ${ac} \n tk: ${tk} \n Response: ${e.response?.data?.msg} \n Response Code: ${e.response?.data?.code}`
          )
        } finally {
          setIsLoading(false)
        }
      }

      const GetQuestionsPost = async () => {
        setIsLoading(true)
        try {
          const response = await axios.post(
            host + GetQuestions,
            { pid: pid },
            {
              headers: {
                'Content-Type': 'text/plain; charset=UTF-8',
                Accept: '*/*',
                ac: ac,
                tk: tk,
              },
            }
          )
          // TODO:
          // console.log('GetQuestionsRes', response.data)
          setReceivedSurvey(response.data)
        } catch (e) {
          console.log(e)
          sendDiscordNotification(
            `信義書院 GetQuestionsPost Error \n Error: ${e}  \n pid: ${pid} \n ac: ${ac} \n tk: ${tk} \n Response: ${e.response?.data?.msg} \n Response Code: ${e.response?.data?.code}`
          )
        } finally {
          setIsLoading(false)
        }
      }
      JoinChatPost()
    }
    // setReceivedSurvey(testJSON)
  }, [isAgree])

  useEffect(() => {
    if (survey) {
      // 下一頁後會拉到最頂端
      survey.onCurrentPageChanged.add(function (survey, options) {
        window.scrollTo(0, 0)
      })
    }
  }, [survey])

  useEffect(() => {
    const convertedToNewSurvey = (receivedSurvey) => {
      if (receivedSurvey) {
        let visibleIfConditions
        // 選項帶入帶出
        let choicesFromQuestions = {}
        // {被帶入選項的題號: 要帶出選項的題號}
        // {"q36": "q35"}
        receivedSurvey.data.forEach((data, currentIndex) => {
          if (data.ru?.op_bring?.b_type === 3) {
            choicesFromQuestions[
              `q${data.ru?.op_bring?.b_qid - 1}`
            ] = `q${currentIndex}`
          }
        })
        if (visibleIfConditionsInit !== null) {
          visibleIfConditions = visibleIfConditionsInit
        } else {
          visibleIfConditions = {}
          receivedSurvey.data.forEach((data, currentIndex) => {
            // 如果 data.ru.route.ta 存在
            if (
              data.ru &&
              data.ru.route &&
              data.ru.route.ty &&
              data.ru.route.ta
            ) {
              let routeArray = data.ru.route.ta
              // TODO: other/none

              // 一開始就要知道會跳幾題
              function findDifference(array) {
                if (array.length === 0) return null // 處理空陣列
                let max = Math.max(...array)
                let min = Math.min(...array)
                if (max === 99) return 0
                return max - min
              }
              let questionsHasVisibleIf = 0
              let diff = findDifference(routeArray)
              questionsHasVisibleIf += diff

              // console.log('questionsHasVisibleIf', questionsHasVisibleIf)

              // [2,2,2,2,2,3,3,3,3,3]
              // [18, 21]
              // [21, 18]
              // [25, 25, 25, 25, 25, 25, 25, 24]
              // [5, 7, 5]

              // 單選跳題
              if (data.ru.route.ty === 1 || data.ru.route.ty === 2) {
                routeArray.forEach((targetIndex, choiceIndex) => {
                  if (targetIndex - 1 === currentIndex + 1) {
                    // 完成作答
                    if (questionsHasVisibleIf === 0) {
                      // console.log('結束作答', questionsHasVisibleIf)
                    } else if (questionsHasVisibleIf > 0) {
                      // 複選要移駕 visibleIfConditionsInit
                      if (checkShowNoneItem(data)) {
                        visibleIfConditions[
                          `q${targetIndex - 1}`
                        ] = `{q${currentIndex}} = 'none'`
                      } else if (
                        // 跳很多題
                        visibleIfConditions.hasOwnProperty(
                          `q${targetIndex - 1}`
                        )
                      ) {
                        for (
                          let i = currentIndex + 1;
                          i <= currentIndex + Math.abs(questionsHasVisibleIf);
                          i++
                        ) {
                          visibleIfConditions[
                            `q${i}`
                          ] += ` or {q${currentIndex}} = ${choiceIndex}`
                        }
                      } else {
                        for (
                          let i = currentIndex + 1;
                          i <= currentIndex + Math.abs(questionsHasVisibleIf);
                          i++
                        ) {
                          visibleIfConditions[
                            `q${i}`
                          ] = `{q${currentIndex}} = ${choiceIndex}`
                        }
                      }
                    }
                  }
                })
                // TODO:
                // console.log('visibleIfConditions', visibleIfConditions)
              }
            }
          })
        }

        // 跳題設定會添加到下一題的表
        // 1.檢查跳題設定 0=無跳題, 1=單選跳題, 2 多選跳題
        // 2.單選跳題 加到物件{ 第X題回答第X選項會去第X題  {q16} == '0' => q21 }
        // {
        //   type: 'skip',
        //   expression: "{q16} = '0'",
        //   gotoName: 'q21',
        // },
        // 3.複選跳題 "{question1} anyof ['Item 1', 'Item 2', 'Item 3']"
        // 若跳題題號 == 當前題號+1 則不須跳題 "ta": [25, 25, 25, 25, 25, 25, 25, 24]
        // "ta": [18, 21]
        // {
        //   "type": "skip",
        //   "expression": "{question1} anyof ['Item 1', 'Item 2', 'Item 3']",
        //   "gotoName": "question4"
        //  }
        // 4.none 跳題 & other 跳題
        // 就算跳題還是會看到題目有visible的問題
        // "visibleIf": "{question1} anyof ['Item 1', 'Item 2', 'Item 3']"
        // 若跳題題號 == 當前題號+1 則沒事
        // 若不 == 當前題號+1 就要隱藏中間的題目 "ta": [18, 21] 隱藏18 19 20 21-17 = 4再減一

        receivedSurvey.data.forEach((data, i, arr) => {
          let newQuestion
          // 有圖片
          if (data.ma.image) {
            newQuestion = {
              type: 'html',
              name: 'image' + i,
              html: `<div  class="singleImgWrap"><img src="${data.ma.image}" alt='image'></div>`,
              visibleIf: visibleIfConditions[`q${i}`]
                ? visibleIfConditions[`q${i}`]
                : '',
              imagesReadingTime: imagesReadingTime,
            }
            // 手動煥頁
            if (pageBreakQuestions) {
              if (pageBreakQuestions.includes(newQuestion.name)) {
                tempSurvey.pages.push({
                  name: `page${pageOrderRef.current}`,
                  elements: [newQuestion],
                })

                pageOrderRef.current = pageOrderRef.current + 1
              } else {
                // 否則，在當前頁面加入這個問題
                const currentPage =
                  tempSurvey.pages[tempSurvey.pages.length - 1]
                currentPage.elements.push(newQuestion)
              }
            } else {
              if (data.cf.parameters.page === 1) {
                tempSurvey.pages.push({
                  name: `page${pageOrderRef.current}`,
                  elements: [newQuestion],
                })

                pageOrderRef.current = pageOrderRef.current + 1
                // console.log('pageOrder', pageOrderRef.current)
              } else {
                // 否則，在當前頁面加入這個問題
                const currentPage =
                  tempSurvey.pages[tempSurvey.pages.length - 1]
                currentPage.elements.push(newQuestion)
              }
            }
          }

          // 多圖
          if (data.ma.images.length > 0) {
            newQuestion = {
              type: 'html',
              name: 'images' + i,
              html: multipleImages(data),
              visibleIf: visibleIfConditions[`q${i}`]
                ? visibleIfConditions[`q${i}`]
                : '',
              // 圖片閱讀時間
              imagesReadingTime: imagesReadingTime,
            }
            if (data.cf.parameters.page === 1) {
              tempSurvey.pages.push({
                name: `page${pageOrderRef.current}`,
                elements: [newQuestion],
              })
              pageOrderRef.current = pageOrderRef.current + 1
            } else {
              // 否則，在當前頁面加入這個問題
              const currentPage = tempSurvey.pages[tempSurvey.pages.length - 1]
              currentPage.elements.push(newQuestion)
            }
          }

          // 有影片
          if (data.ma.video) {
            const embedId = data.ma.video.split(
              'https://www.youtube.com/watch?v='
            )[1]
            newQuestion = {
              type: 'html',
              name: 'video' + i,
              html: `
              <div class="imgWrap50">
              <h2 class="thirtySecondDelete" style="text-align: center">請仔細觀看 ${imagesReadingTime} 秒後才能進入下一頁</h2>
              <div class="video-responsive">
              <iframe
                width="853"
                height="480"
                src="https://www.youtube.com/embed/${embedId}"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              /></div></div>`,
              visibleIf: visibleIfConditions[`q${i}`]
                ? visibleIfConditions[`q${i}`]
                : '',
            }

            // 手動煥頁
            if (pageBreakQuestions) {
              if (pageBreakQuestions.includes(newQuestion.name)) {
                tempSurvey.pages.push({
                  name: `page${pageOrderRef.current}`,
                  elements: [newQuestion],
                })

                pageOrderRef.current = pageOrderRef.current + 1
              } else {
                // 否則，在當前頁面加入這個問題
                const currentPage =
                  tempSurvey.pages[tempSurvey.pages.length - 1]
                currentPage.elements.push(newQuestion)
              }
            } else {
              if (data.cf.parameters.page === 1) {
                tempSurvey.pages.push({
                  name: `page${pageOrderRef.current}`,
                  elements: [newQuestion],
                })

                pageOrderRef.current = pageOrderRef.current + 1
                // console.log('pageOrder', pageOrderRef.current)
              } else {
                // 否則，在當前頁面加入這個問題
                const currentPage =
                  tempSurvey.pages[tempSurvey.pages.length - 1]
                currentPage.elements.push(newQuestion)
              }
            }
          }

          // 題型
          let type = ''
          switch (data.cf.q_type) {
            case 1:
              type = 'radiogroup'
              break
            case 2:
              switch (data.op[0]?.o_type) {
                case 0:
                  type = 'checkbox'
                  break
                case 1:
                  type = 'imagepicker'
                  break

                default:
                  type = 'checkbox'
                  break
              }
              break
            case 3:
              type = 'dropdown'
              break
            case 4:
              switch (data.op[0]?.o_type) {
                case 0:
                  type = 'comment'
                  break
                case 1:
                  type = 'text'
                  break
                case 2:
                  type = 'file'
                  break
                case 3:
                  type = 'file'
                  break

                default:
                  type = 'text'
                  break
              }
              break
            case 5:
              type = 'nouislider'
              break
            case 6:
              switch (data.cf.parameters.row_type) {
                case 0:
                  type = 'matrix'
                  break
                case 1:
                  type = 'matrix'
                  break

                default:
                  type = 'matrix'
                  break
              }
              break
            case 7:
              type = 'ranking'
              break
            case 8:
              type = 'boolean'
              break
            case 9:
              type = 'dropdown'
              break
            default:
              type = ''
              break
          }
          // 標題&副標
          const firstBreakIndex = data.ma.content.indexOf('\n\n')
          const splittedContent = data.ma.content.split('\n\n')
          newQuestion = {
            type: type,
            name: 'q' + i,
            title: splittedContent[0],
            description:
              splittedContent.length > 1
                ? data.ma.content.slice(firstBreakIndex + 2)
                : '',
            // TODO: true
            isRequired: notRequired === null ? true : checkNotRequired(i),
            isAllRowRequired: true,
            // 矩陣的設定
            requiredInAllRowsError: '請回答所有問題',
            alternateRows: true,
            hideNumber: true,
            requiredErrorText: '此題必填',
            choices:
              data.cf.q_type === 3
                ? convertDropdownChoices(data)
                : convertChoices(data),
            imageFit: data.op[0]?.o_type === 1 ? 'contain' : '',
            maxImageWidth: data.op[0]?.o_type === 1 ? 200 : '',
            showLabel: data.op[0]?.o_type === 1 ? true : false,
            multiSelect: data.op[0]?.o_type === 1 ? true : false,
            // 文字字數上限
            maxLength: data.op[0]?.o_type === 0 ? data.op[0].limit?.max : 1000,
            // 數字上下限
            inputType: data.op[0]?.o_type === 1 ? 'number' : '',
            min: data.op[0]?.o_type === 1 ? data.op[0].limit?.min : 0,
            max: data.op[0]?.o_type === 1 ? data.op[0].limit?.max : 2000,
            showNoneItem: checkShowNoneItem(data),
            noneText: checkNoneText(data),
            maxSelectedChoices: data.cf.parameters.limit
              ? data.cf.parameters.limit.max
              : 100,
            minSelectedChoices:
              data.cf.parameters.limit?.min > 1
                ? data.cf.parameters.limit.min
                : data.cf.q_type === 7
                ? 1
                : 0,
            // choicesOrder:
            //   data.cf.parameters.random.is_rand === 1 ? 'random' : 'none',
            separateSpecialChoices: true,
            showOtherItem: checkShowOtherItem(data),
            otherText: checkOtherText(data),
            placeholder: checkPlaceholder(data),
            // 矩陣
            columns: data.cf.parameters.row_op
              ? convertMatrixColumns(data)
              : '',
            rows: data.cf.parameters.row_op ? convertMatrixRows(data) : '',
            minErrorText: '數字不得小於 {0}',
            maxErrorText: '數字不得大於 {0}',
            minSelectErrorText: '至少選擇 {0} 個選項',
            minSelectedChoicesText: '至少選擇 {0} 個選項',
            rowTitleWidth: data.cf.parameters.row_op && !isMobile ? '50%' : '',
            columnMinWidth: data.cf.parameters.row_op ? '50px' : '',
            // 尺規題
            // autoGenerate: data.cf.q_type === 5 ? false : '',
            // rateCount: data.cf.q_type === 5 ? data.op[0].max : '',
            // rateMax: data.cf.q_type === 5 ? data.op[0].max : '',
            // rateValues: data.cf.q_type === 5 ? convertRateValues() : '',
            // displayMode: data.cf.q_type === 5 ? 'buttons' : '',
            step: data.cf.q_type === 5 ? 1 : '',
            rangeMin: data.cf.q_type === 5 ? 1 : '',
            rangeMax: data.cf.q_type === 5 ? 7 : '',
            pipsMode: data.cf.q_type === 5 ? 'steps' : '',
            selectToRankEnabled: true,
            selectToRankAreasLayout: isMobile ? 'vertical' : '',
            selectToRankEmptyRankedAreaText: '所有選項已排序完成',
            selectToRankEmptyUnrankedAreaText: '拖放選項到此進行排序',
            // defaultVaule: type === 7 ? [] : null,
          }

          // 如果data.ru.route.ta存在，将条件存储到visibleIfConditions
          // 如果存在当前问题的 visibleIf 条件，将它添加到 newQuestion.visibleIf 中
          if (visibleIfConditions[`q${i}`]) {
            newQuestion.visibleIf = visibleIfConditions[`q${i}`]
          }

          // 選項帶入(目前沒辦法帶none/other)
          // if (
          //   choicesFromQuestions[`q${i}`] &&
          //   data.ru?.op_bring?.b_type === 2
          // ) {
          //   newQuestion.choicesFromQuestion = choicesFromQuestions[`q${i}`]
          //   console.log('choicesFromQuestions', choicesFromQuestions)
          //   // if (arr[i - 1]) {

          //   // }
          //   // console.log('tempSurvey', tempSurvey)
          // }
          if (triggersConditionsInit !== null) {
            tempSurvey.triggers = triggersConditionsInit
          } else if (
            data.ru?.route?.ty &&
            data.ru?.route?.ta &&
            data.ru.route.ta.filter((v) => v === 99).length > 0
          ) {
            const question = 'q' + i
            data.ru.route.ta.filter((v) => v === 99)
            const answerWillCompleteSurvey = data.ru.route.ta.findIndex(
              (element) => element === 99
            )
            tempSurvey.triggers.push({
              type: 'complete',
              expression: `{${question}} = ${answerWillCompleteSurvey}`,
            })
          }

          // 頁數
          if (newQuestion) {
            // 手動煥頁
            if (pageBreakQuestions) {
              if (pageBreakQuestions.includes(newQuestion.name)) {
                tempSurvey.pages.push({
                  name: `page${pageOrderRef.current}`,
                  elements: [newQuestion],
                })

                pageOrderRef.current = pageOrderRef.current + 1
              } else {
                // 否則，在當前頁面加入這個問題
                const currentPage =
                  tempSurvey.pages[tempSurvey.pages.length - 1]
                currentPage.elements.push(newQuestion)
              }
            } else {
              if (data.cf.parameters.page === 1) {
                tempSurvey.pages.push({
                  name: `page${pageOrderRef.current}`,
                  elements: [newQuestion],
                })

                pageOrderRef.current = pageOrderRef.current + 1
                // console.log('pageOrder', pageOrderRef.current)
              } else {
                // 否則，在當前頁面加入這個問題
                const currentPage =
                  tempSurvey.pages[tempSurvey.pages.length - 1]
                currentPage.elements.push(newQuestion)
              }
            }
          }
        })
        setNewSurvey(tempSurvey)
      }
    }
    convertedToNewSurvey(receivedSurvey)
  }, [receivedSurvey])

  useEffect(() => {
    if (newSurvey) {
      setconvertedSurvey(newSurvey)
      // TODO:
      // console.log('newSurvey', newSurvey)
    }
  }, [newSurvey])

  return {}
}
