import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import noUiSlider from 'nouislider'
import { nouislider } from 'surveyjs-widgets'
import * as SurveyCore from 'survey-core'
import 'nouislider/dist/nouislider.css'
// nouislider(SurveyCore)

export function useSubmitAnswers({
  survey,
  pid,
  pi,
  ac,
  tk,
  hideSdBtnIfImgWrapExists,
  setIsLoading,
  helpFunction,
  canShowPrevButtonPages,
  setImagesReadingTime,
  updateStringComponents,
  choicesOrderRef,
  choiceIndexMapRef,
}) {
  const host = 'https://nezcdev.dataa.com.tw'
  // 使⽤者上傳填答結果
  const SubmitAnswersUrl = '/clientWeb/v1/SubmitAnswers'

  // discord
  async function sendDiscordNotification(message) {
    try {
      await axios.post('/api/send-notification', {
        content: message,
      })
    } catch (error) {
      console.error('Failed', error)
    }
  }

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

  const timestamps = {}
  const transformedData = useRef([])
  // 選項順序排列
  // const choicesOrderRef = useRef({})
  // const choiceIndexMapRef = useRef({})

  // 拿來存 other 選項的 index
  const otherAnswers = {}
  const noneAnswers = {}
  const matrixAnswers = {}
  // let [surveyData, setSurveyData] = useState({
  //   pid: pid,
  //   answers: [...transformedData.current].sort(function (a, b) {
  //     return a.qi - b.qi
  //   }),
  // })
  // useEffect(() => {
  //   const newSurveyData = {
  //     pid: pid,
  //     answers: [...transformedData.current].sort(function (a, b) {
  //       return a.qi - b.qi
  //     }),
  //   }
  //   setSurveyData(newSurveyData)
  //   console.log('newsurveydata', newSurveyData)
  // }, [transformedData])

  // useEffect(() => {
  // if (survey) {
  // 下一頁後會拉到最頂端
  survey.onCurrentPageChanged.add(function (survey, options) {
    window.scrollTo(0, 0)
  })

  survey.onAfterRenderQuestion.add((survey, options) => {
    // 專有名詞備註
    if (typeof updateStringComponents === 'function') {
      // 如果存在，调用该函数
      updateStringComponents(survey, options)
    }

    // let order = options.question.visibleChoices
    // console.log('order onAfterRenderQuestion', order)
    // let matrixOrder = options.question.columns
    // hideSdBtnIfImgWrapExists()
    // // 初始化一個物件來存儲所有問題的值和索引的對應關系
    // // if (!survey.choiceIndexMap) {
    // //   survey.choiceIndexMap = {}
    // // }
    // if (order) {
    //   let orderMap = order.map((choice) => choice.value)
    //   // console.log('orderMap', orderMap)
    //   choicesOrderRef.current[options.question.name] = orderMap
    //   // 建立一個物件來存儲當前問題的值和索引的對應關系
    //   choiceIndexMapRef.current[options.question.name] = order.reduce(
    //     (map, choice, index) => {
    //       map[choice.value] = index
    //       return map
    //     },
    //     {}
    //   )

    //   // 只有 other
    //   if (orderMap.includes('other') && orderMap.indexOf('none') === -1) {
    //     const otherIndex = orderMap.indexOf('other')
    //     orderMap[otherIndex] = orderMap.length - 1
    //     choicesOrderRef.current[options.question.name] = orderMap
    //   }

    //   // 只有 none
    //   if (orderMap.includes('none') && orderMap.indexOf('other') === -1) {
    //     const noneIndex = orderMap.indexOf('none')
    //     orderMap[noneIndex] = orderMap.length - 1
    //     choicesOrderRef.current[options.question.name] = orderMap
    //   }

    //   if (orderMap.includes('other') && orderMap.includes('none')) {
    //     const otherIndex = orderMap.indexOf('other')
    //     const noneIndex = orderMap.indexOf('none')
    //     orderMap[otherIndex] = orderMap.length - 1
    //     orderMap[noneIndex] = orderMap.length - 2
    //     choicesOrderRef.current[options.question.name] = orderMap
    //   }
    // } else {
    //   choicesOrderRef.current[options.question.name] = []
    //   choiceIndexMapRef.current[options.question.name] = {}
    // }
    // // 矩陣 order
    // if (matrixOrder && matrixOrder.length > 0) {
    //   const matrixChoicesOrder = matrixOrder.map((v) => v.value)
    //   choicesOrderRef.current[options.question.name] = matrixChoicesOrder
    // }
    // console.log('choicesOrderRef', choicesOrderRef)

    // 圖片直的橫的影響他的大小
    const images = document.querySelectorAll('img')
    Array.from(images).forEach((image) => {
      image.addEventListener('load', () => fitImage(image))

      if (image.complete && image.naturalWidth !== 0) fitImage(image)
    })

    function fitImage(image) {
      const aspectRatio = image.naturalWidth / image.naturalHeight
      // If image is portrait
      if (aspectRatio < 1 && !isMobile) {
        // console.log('直式圖片')
        // image.style.width = '60%'
      }
    }
    // setImagesReadingTime(3)

    // 顯示上一頁按鈕
    if (canShowPrevButtonPages !== null) {
      const prevBtn = document.querySelector('#sv-nav-prev')
      const page = options.question.page
      const pageName = page ? page.name : null
      if (prevBtn) {
        for (let i = 0; i < canShowPrevButtonPages.length; i++) {
          if (pageName === 'page' + canShowPrevButtonPages[i]) {
            prevBtn.style.display = 'block'
            return
          } else {
            prevBtn.style.display = 'none'
          }
        }
      }
    }
  })

  survey.onValueChanged.add(function (survey, options) {
    helpFunction(survey, options)
    let order = options.question.visibleChoices
    let matrixOrder = options.question.columns
    hideSdBtnIfImgWrapExists()

    if (order) {
      let orderMap = order.map((choice) => choice.value)
      choicesOrderRef.current[options.question.name] = orderMap
      // 建立一個物件來存儲當前問題的值和索引的對應關系
      if (!choiceIndexMapRef.current[options.question.name]) {
        choiceIndexMapRef.current[options.question.name] = order.reduce(
          (map, choice, index) => {
            map[choice.value] = index
            return map
          },
          {}
        )
      }
      // console.log('choiceIndexMapRef', choiceIndexMapRef)

      // 只有 other
      if (orderMap.includes('other') && orderMap.indexOf('none') === -1) {
        const otherIndex = orderMap.indexOf('other')
        orderMap[otherIndex] = orderMap.length - 1
        choicesOrderRef.current[options.question.name] = orderMap
      }

      // 只有 none
      if (orderMap.includes('none') && orderMap.indexOf('other') === -1) {
        const noneIndex = orderMap.indexOf('none')
        orderMap[noneIndex] = orderMap.length - 1
        choicesOrderRef.current[options.question.name] = orderMap
      }

      if (orderMap.includes('other') && orderMap.includes('none')) {
        const otherIndex = orderMap.indexOf('other')
        const noneIndex = orderMap.indexOf('none')
        orderMap[otherIndex] = orderMap.length - 1
        orderMap[noneIndex] = orderMap.length - 2
        choicesOrderRef.current[options.question.name] = orderMap
      }
    } else {
      choicesOrderRef.current[options.question.name] = []
      choiceIndexMapRef.current[options.question.name] = {}
    }
    // 矩陣 order
    if (matrixOrder && matrixOrder.length > 0) {
      const matrixChoicesOrder = matrixOrder.map((v) => v.value)
      choicesOrderRef.current[options.question.name] = matrixChoicesOrder
    }

    // 登記時間
    timestamps[options.name] = Date.now()
    // 改 other
    const question = options.question

    // ======================== 矩陣 =============================
    if (question.columns?.length > 0) {
      // console.log('矩陣 question', question)

      let value = question.value
      // console.log('矩陣 value', value)
      let arrValue = Object.values(value)
      let mapedArrValue = arrValue.map((v) => {
        return [v]
      })
      matrixAnswers[question.name] = mapedArrValue

      // console.log('矩陣 matrixAnswers', matrixAnswers)
    }
    if (question.showOtherItem) {
      let value = question.value

      // 複選 other
      if (value && Array.isArray(value) && value.indexOf('other') !== -1) {
        //  OtherIndex 要存在 otherAnswers裡
        //  Other 內容要在complete才會有==
        otherAnswers[question.name] = {
          otherIndex: question.choices.length + (question.showNoneItem ? 1 : 0),
        }
      }

      // 單選 other
      if (value && value === 'other') {
        otherAnswers[question.name] = {
          otherIndex: question.choices.length + (question.showNoneItem ? 1 : 0),
        }
      }
    }
    if (question.showNoneItem) {
      let value = question.value

      if (value && value.indexOf('none') !== -1) {
        noneAnswers[question.name] = {
          noneIndex: question.choices.length,
        }
      }
    }
  })

  survey.onCompleting.add((sender, options) => {
    let surveyData = sender.data
    // console.log('surveyData', surveyData)
    // 留著有用可以用
    // const allQuestions = survey.getAllQuestions()
    // console.log(
    //   'survey.getAllQuestions()',
    //   allQuestions.forEach(function (question) {
    //     console.log('問題名：', question.name)
    //     console.log('問題類型：', question.getType())
    //     console.log('答案：', survey.data[question.name])
    //     console.log('isRandom', question.areChoicesRandomized)
    //   })
    // )
    // console.log('survey.choiceIndexMap', survey.choiceIndexMap)
    // 建立一個物件來存儲用戶的答案的索引
    const answerIndexes = {}
    for (let questionName in surveyData) {
      // console.log('choiceIndexMap 2', choiceIndexMapRef)
      if (choiceIndexMapRef.current[questionName]) {
        // 獲取當前問題的答案的值
        let answerValue = surveyData[questionName]
        // console.log('answerValue', answerValue)
        // 檢查 answerValue 是否是一個陣列
        if (Array.isArray(answerValue)) {
          // 如果是陣列，則對每個元素找到其索引
          answerIndexes[questionName] = answerValue.map((value) =>
            typeof choiceIndexMapRef.current[questionName][value] === 'number'
              ? choiceIndexMapRef.current[questionName][value]
              : choiceIndexMapRef.current[questionName][value].index
          )
        } else {
          // 如果不是陣列，則直接找到答案的索引
          answerIndexes[questionName] =
            choiceIndexMapRef.current[questionName][answerValue]
        }
      }
    }
    // 現在，answerIndexes 物件會包含用戶答案的索引
    // console.log('answerIndexes', answerIndexes)

    let newTransformedData = Object.entries(surveyData).reduce(
      (acc, [key, value]) => {
        if (key.includes('-Comment')) {
          let actualKey = key.split('-')[0]
          let correspondingObj = acc.find(
            (obj) => obj.qi === Number(actualKey.slice(1))
          )

          if (correspondingObj) {
            let otherKey = otherAnswers[actualKey].otherIndex
            correspondingObj.other[otherKey] = value
          }

          return acc
        }

        let ans = []
        if (answerIndexes[key] || answerIndexes[key] === 0) {
          // console.log('value', key, ':', value)
          // 單選多選 有就放
          if (Array.isArray(answerIndexes[key])) {
            ans = answerIndexes[key]
            // console.log('1')
          } else if (typeof value === 'object' && !Array.isArray(value)) {
            ans = matrixAnswers[key]
            // console.log('矩陣', matrixAnswers)
          } else if (value === 'other') {
            // 單選 other
            ans = [otherAnswers[key].otherIndex]
            // console.log('other', key)
          } else if (value === 'none') {
            // 單選 none
            ans = [noneAnswers[key].noneIndex]
            // console.log('none', key)
          } else if (answerIndexes[key].title) {
            ans = [answerIndexes[key].index]
            // console.log('帶入', key)
          } else {
            ans = [answerIndexes[key]]
            // console.log('單選', key)
          }
        } else if (value === 'other') {
          // 單選 other
          ans = [otherAnswers[key].otherIndex]
          // console.log('other', key)
        } else if (value === 'none') {
          // 單選 none
          ans = [noneAnswers[key].noneIndex]
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          // 矩陣
          ans = matrixAnswers[key]
        } else if (Array.isArray(value)) {
          // 多選 other & none
          ans = value.map((v) =>
            v === 'other'
              ? otherAnswers[key].otherIndex
              : v === 'none'
              ? noneAnswers[key].noneIndex
              : v
          )
        } else {
          // 開放題
          ans = [value]
        }

        acc.push({
          pi: pi,
          ans: ans,
          ord: choicesOrderRef.current[key],
          other: {},
          ct: timestamps[key],
          qi: Number(key.slice(1)),
          ...(choiceIndexMapRef?.current[key][0]?.title
            ? {
                bringing_options: {
                  options: Object.values(choiceIndexMapRef.current[key]).map(
                    (v) => {
                      return { o_type: 0, na: v.title }
                    }
                  ),
                },
              }
            : {}),
        })

        return acc
      },
      []
    )
    transformedData.current = newTransformedData
    // console.log('transformedData', transformedData)
  })

  survey.onComplete.add((sender, options) => {
    // setIsLoading(true)
    const ans = [...transformedData.current]
    const sortedAns = ans.sort(function (a, b) {
      return a.qi - b.qi
    })
    let surveyData = {
      pid: pid,
      answers: sortedAns,
    }

    axios
      .post(host + SubmitAnswersUrl, surveyData, {
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8',
          Accept: '*/*',
          ac: ac,
          tk: tk,
        },
      })
      .then((res) => {
        // 03102 答案格式與題目不符

        if (res.data.code === '05000') {
          navigate(`/pid=${pid}/submitFail`)
          sendDiscordNotification(
            `信義書院 Submit Error \n Error: 伺服器壞ㄌ ${
              res.data.code
            }  \n surveyData: ${JSON.stringify(
              surveyData
            )}  \n ac: ${ac} \n tk: ${tk} `
          )
        } else if (res.data.code === '03044') {
          navigate(`/pid=${pid}/done`)
        } else if (res.data.code !== '02000') {
          navigate(`/pid=${pid}/submitFail`)
          sendDiscordNotification(
            `信義書院 Submit Error \n Error: 問卷送出有問題${
              res.data.code
            }  \n surveyData: ${JSON.stringify(
              surveyData
            )}  \n ac: ${ac} \n tk: ${tk} `
          )
        }
      })
      .catch((e) => {
        console.log(e)
        navigate(`/pid=${pid}/submitFail`)
        sendDiscordNotification(
          `信義書院 Submit Error \n Error: 伺服器壞ㄌ ${e}  \n surveyData: ${JSON.stringify(
            surveyData
          )} \n Response: ${e.response?.data?.msg} \n Response Code: ${
            e.response?.data?.code
          } \n ac: ${ac} \n tk: ${tk} `
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
    // TODO:
    // console.log('繳交答案', surveyData)
  })

  return {}
}
