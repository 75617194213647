import React from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import { templateJson } from './json/templateJson'

// import { useNavigate } from 'react-router-dom'

const IsSubmitted = () => {
  let mySubmittedJson = {
    ...templateJson,
    completeText: '完成',
    completedHtml: `<h3>感謝您的參與</h3> <h4>如有填卷相關問題請洽：</h4><a href='mailto:chuheng@nccu.edu.tw'>chuheng@nccu.edu.tw</a><h4 style='margin-top:50px'>更多企業倫理與永續經營訊息</h4> <a href='https://syschool.nccu.edu.tw/'>https://syschool.nccu.edu.tw/</a>`,
    pages: [
      {
        name: 'page0',
        elements: [
          {
            type: 'panel',
            elements: [
              {
                type: 'html',
                name: 'intro',
                html: "<h2 style='text-align: center'>您已填過此問卷</h2>",
              },
            ],
            name: 'panel1',
          },
        ],
      },
    ],
  }
  const survey = new Model(mySubmittedJson)

  return <Survey model={survey} />
}

export default IsSubmitted
