import React, { useState } from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import '../index.css'
import { templateJson } from './json/templateJson'
import { useConvertSurvey } from '../hooks/useConvertSurvey'
import 'survey-core/i18n/traditional-chinese'

const Agree = ({
  ac,
  tk,
  setconvertedSurvey,
  pid,
  setIsLoading,
  imagesReadingTime,
}) => {
  const [myAgreeJson, setMyAgreeJson] = useState({
    ...templateJson,
    width: '800px',
    completedHtml: `<div class="sd-element--with-frame"><h3>問卷準備中...</h3><h3>若逾時過久請連絡小編，謝謝</h3></div>`,
    completeText: '開始作答',
    completedBeforeHtml: '<h3>您已填過此問卷</h3>',
    pages: [
      {
        name: 'page0',
        elements: [
          {
            type: 'panel',
            elements: [
              {
                type: 'html',
                name: 'personalData',
                html: "<article class='personalData'><h2 class='intro__heading title'>研究知情說明</h2><p><u>資料儲存方式</u>：本問卷採公司記名及有可能直接辨識填答者個人的方式作答，其中，可辨識個人資訊的資料，將與問卷填寫內容分開存檔，所蒐集的個人及公司背景資訊將保存至2029年10月即刪除。研究團隊將盡全力維護您的隱私及善盡保密責任，減少各種可能的風險。</p><p><u>政大商學院信義書院的資料利用方式</u>：政大商學院信義書院對於此研究所蒐集之資料，未來將採整體分析對外進行公開發表，您與您的公司都不會被辨識出，調查資料將用以發佈【2024台灣企業ESG轉型需求報告書】，亦無衍生的商業利益。請您自由決定是否填寫，亦可中途不填寫，無需感到壓力。</p><p><u>上銀科技的資料利用方式</u>：本問卷版本為上銀科技專用版，本問卷連結與本問卷電子檔所蒐集之資料，亦作為上銀科技規劃永續供應鏈教育訓練之參考。當您願意填寫本問卷，亦同意上銀科技可取得並使用本問卷所蒐集之資料。</p><p>若您想詢問本問卷內容，請洽詢信義書院李竺姮研究總監，電話：(02)2341-9151分機1814，email: <u><a href='mailto:chuheng@nccu.edu.tw'>chuheng@nccu.edu.tw</a></u>。</p><p style='text-align: right'>國立政治大學商學院信義書院</p><p style='text-align: right'>別蓮蒂執行長 敬上</p></article>",
              },
              {
                type: 'radiogroup',
                name: 'agree',
                title: '如果您同意參加，請點選「我同意」開始填寫問卷',
                isRequired: true,
                requiredErrorText: '此題必填',
                hideNumber: true,
                choices: [
                  {
                    value: 1,
                    text: '我同意',
                  },
                ],
              },
            ],
            name: 'panel1',
          },
        ],
      },
    ],
  })

  const completedHtml = `<p>由衷感謝您的寶貴時間與支持。</p><p>歡迎您將這份問卷連結，轉傳給更多企業主，或負責企業永續的企業朋友。</p><p>因為有大家的參與，對於共同探尋適合台灣企業的永續轉型之道，有莫大助益。</p>`

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const survey = new Model(myAgreeJson)

  // 問卷編號
  // const pid = pid
  // 跳題 小心複選題要用陣列
  const visibleIfConditionsInit = {
    // q18: '{q17} anyof [2]',
    q19: '({q13} != 4 or {q10} != 4 or {q11} != 4 or {q12} != 4) and ({q13} != 0 or {q10} != 0 or {q11} != 0 or {q12} != 0)',
    q20: '({q13} != 4 or {q10} != 4 or {q11} != 4 or {q12} != 4) and ({q13} != 0 or {q10} != 0 or {q11} != 0 or {q12} != 0)',
    q42: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q43: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q44: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q45: '{q44} = 1 and ({q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0)',
    q46: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q47: '{q46} = 2 and ({q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0)',
    q48: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q49: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q50: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q51: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q52: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q53: '{q13} <> 0 or {q10} <> 0 or {q11} <> 0 or {q12} <> 0',
    q54: '{q13} = 0 and {q10} = 0 and {q11} = 0 and {q12} = 0',
    q63: '{q62} = 3',
    q65: '{q64} = 0',
  }

  // 非必填(從 0 計算)
  // TODO:
  const notRequired = [0, 8, 9, 22, 33, 41, 57]

  // 尺規選項
  const mappedRateChoice = null
  const pageBreakQuestions = null
  const triggersConditionsInit = null

  useConvertSurvey({
    survey,
    pid,
    ac,
    tk,
    myAgreeJson,
    setMyAgreeJson,
    setconvertedSurvey,
    imagesReadingTime,
    templateJson,
    setIsLoading,
    completedHtml,
    visibleIfConditionsInit,
    notRequired,
    mappedRateChoice,
    pageBreakQuestions,
    triggersConditionsInit,
  })
  return <Survey model={survey} />
}

export default Agree
